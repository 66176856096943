import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ButtonStatic } from "@components/Button"
import workData from "@json/work.json"

const Work = ({ client, reverse }) => {
  const data = useStaticQuery(query)
  const { slug, name, headline, theme } = workData[client]

  return (
    <div className="grid w-full grid-cols-12">
      <Link
        to={`/work/${slug}`}
        className="col-span-11 carousel-lg:col-span-12 carousel-lg:mr-20"
        style={{ cursor: "none" }}
      >
        <div
          className={`${theme.background} md:aspect-w-616 md:aspect-h-742 carousel-lg:aspect-w-1436 carousel-lg:aspect-h-920`}
        >
          <div className={`carousel-lg:flex ${reverse && "flex flex-col-reverse carousel-lg:flex-row-reverse"}`}>
            <div className="relative z-10 overflow-hidden md:h-1/2 carousel-lg:h-full carousel-lg:w-1/2">
              <div className="w-full h-full pointer-events-none">
                <div className="hidden w-full h-full lg:block">
                  <GatsbyImage
                    className="w-full h-full"
                    image={getImage(data[`${client}Desktop`])}
                    loading="true"
                    alt={`${name} Feature`}
                  />
                </div>
                <div className="hidden w-full h-full md:block carousel-lg:hidden">
                  <GatsbyImage
                    className="w-full h-full"
                    image={getImage(data[`${client}Tablet`])}
                    loading="true"
                    alt={`${name} Feature`}
                  />
                </div>
                <div className="aspect-w-303 aspect-h-310 md:hidden">
                  <div className="absolute inset-0">
                    <GatsbyImage
                      className="w-full h-full"
                      image={getImage(data[`${client}Mobile`])}
                      loading="true"
                      alt={`${name} Feature`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:h-1/2 md:flex-row carousel-lg:flex-col carousel-lg:h-full carousel-lg:flex-wrap carousel-lg:w-1/2">
              <div
                className={`${theme.backgroundHome} flex aspect-w-303 aspect-h-214 md:aspect-none md:w-1/2 carousel-lg:w-full carousel-lg:h-1/2`}
              >
                <div className={`h-full flex justify-center items-center ${theme.navigationHomeColor}`}>
                  <div className="w-3/5 lg:w-8/12">
                    <p>{headline}</p>
                    <div className="mt-auto md:mt-12">
                      <ButtonStatic className={theme.navigationHomeColor}>View case study</ButtonStatic>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative aspect-w-303 aspect-h-214 md:aspect-none md:w-1/2 carousel-lg:w-full carousel-lg:h-1/2">
                <div className="absolute pointer-events-none md:h-full">
                  <GatsbyImage
                    className="hidden w-full h-full carousel-lg:block"
                    image={getImage(data[`${client}ShowcaseDesktop`])}
                    loading="true"
                    alt={`${name} Feature`}
                  />
                  <GatsbyImage
                    className="hidden w-full h-full md:block carousel-lg:hidden"
                    image={getImage(data[`${client}ShowcaseTablet`])}
                    loading="true"
                    alt={`${name} Feature`}
                  />
                  <GatsbyImage
                    className="w-full h-full md:hidden"
                    image={getImage(data[`${client}ShowcaseMobile`])}
                    loading="true"
                    alt={`${name} Feature`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const MemoizedWork = React.memo(Work)

const query = graphql`
  query {
    bpmDesktop: file(relativePath: { eq: "work/bpm/home-featured-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmTablet: file(relativePath: { eq: "work/bpm/home-featured-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmMobile: file(relativePath: { eq: "work/bpm/home-featured-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmShowcaseDesktop: file(relativePath: { eq: "work/bpm/home-showcase-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmShowcaseTablet: file(relativePath: { eq: "work/bpm/home-showcase-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bpmShowcaseMobile: file(relativePath: { eq: "work/bpm/home-showcase-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiDesktop: file(relativePath: { eq: "work/lumendi/home-featured-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiTablet: file(relativePath: { eq: "work/lumendi/home-featured-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiMobile: file(relativePath: { eq: "work/lumendi/home-featured-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiShowcaseDesktop: file(relativePath: { eq: "work/lumendi/home-showcase-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiShowcaseTablet: file(relativePath: { eq: "work/lumendi/home-showcase-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiShowcaseMobile: file(relativePath: { eq: "work/lumendi/home-showcase-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohDesktop: file(relativePath: { eq: "work/ricoh/home-featured-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohTablet: file(relativePath: { eq: "work/ricoh/home-featured-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohMobile: file(relativePath: { eq: "work/ricoh/home-featured-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohShowcaseDesktop: file(relativePath: { eq: "work/ricoh/home-showcase-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohShowcaseTablet: file(relativePath: { eq: "work/ricoh/home-showcase-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohShowcaseMobile: file(relativePath: { eq: "work/ricoh/home-showcase-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertDesktop: file(relativePath: { eq: "work/weichert/home-featured-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertTablet: file(relativePath: { eq: "work/weichert/home-featured-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertMobile: file(relativePath: { eq: "work/weichert/home-featured-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertShowcaseDesktop: file(relativePath: { eq: "work/weichert/home-showcase-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertShowcaseTablet: file(relativePath: { eq: "work/weichert/home-showcase-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    weichertShowcaseMobile: file(relativePath: { eq: "work/weichert/home-showcase-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbDesktop: file(relativePath: { eq: "work/wb/home-featured-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbTablet: file(relativePath: { eq: "work/wb/home-featured-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbMobile: file(relativePath: { eq: "work/wb/home-featured-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbShowcaseDesktop: file(relativePath: { eq: "work/wb/home-showcase-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbShowcaseTablet: file(relativePath: { eq: "work/wb/home-showcase-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wbShowcaseMobile: file(relativePath: { eq: "work/wb/home-showcase-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
